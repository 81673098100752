/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState } from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Container, Page, Intro, button } from '../styles';
import Layout from '../components/Layout';
import Event from '../components/Event';
import DividerPanda from '../components/DividerPanda';
import moment from 'moment';

const Events = styled('ul')`
    list-style: none;
`;

const MoreEvents = styled('div')`
    overflow: hidden;
    max-height: 0;
    transition: max-height 1.5s cubic-bezier(0, 1, 0, 1);
    ${({ expanded }) => {
        return (
            expanded &&
            css`
                max-height: 10000px;
                transition: max-height 1s ease-in-out;
            `
        );
    }}
`;

const fireGAEvent = (label) => {
    // fire click event for Google Analytics
    if (typeof window !== 'undefined' && typeof window.ga !== 'undefined') {
        window.ga('send', 'event', {
            eventCategory: 'UI',
            eventAction: 'click',
            eventLabel: label,
        });
    }
};

const parseDate = (date) => {
    const parsed = moment(date).parseZone();
    if (parsed.isValid()) {
        return {
            day: moment(parsed).format('dddd'),
            month: moment(parsed).format('MMMM'),
            date: moment(parsed).format('D'),
            time: moment(parsed).format('h A'),
        };
    }

    return false;
};

const renderEvents = (events) => {
    let firstSix = [];
    let allTheRest = [];
    events.forEach(({ start, end, visibility, ...rest }, i) => {
        // build date object
        const date = parseDate(start.dateTime);
        const endTime = parseDate(end.dateTime);

        if (!date || !endTime || visibility === 'private') {
            return;
        }

        date.endTime = endTime.time;
        // only render events if they're in the future
        const renderedEvent = moment().isAfter(end.dateTime) ? null : (
            <Event key={i} date={date} {...rest} />
        );

        if (firstSix.length < 7) {
            firstSix.push(renderedEvent);
            return;
        }
        allTheRest.push(renderedEvent);
    });

    return { firstSix, allTheRest };
};

const EventsList = ({ events }) => {
    const [expanded, setExpanded] = useState(false);
    const { firstSix, allTheRest } = renderEvents(events);

    return (
        <div>
            <h3>Upcoming events:</h3>
            <Events>
                <div>{firstSix}</div>
                <MoreEvents expanded={expanded}>{allTheRest}</MoreEvents>
                <button
                    css={button}
                    onClick={() => {
                        fireGAEvent('Expand Events List');
                        setExpanded(!expanded);
                    }}
                >
                    {expanded ? 'Collapse list' : 'See all'}
                </button>
            </Events>
        </div>
    );
};

const EventsPage = ({
    data: {
        markdownRemark: {
            frontmatter: { title, intro },
        },
        events: { items },
    },
}) => (
    <Layout>
        <Page>
            <Container>
                <Intro>
                    <h1>{title}</h1>
                    <p>{intro}</p>
                </Intro>
            </Container>
            <DividerPanda />
            <Container>
                <EventsList events={items} />
            </Container>
        </Page>
    </Layout>
);

export default EventsPage;

export const query = graphql`
    query {
        markdownRemark(frontmatter: { id: { eq: "events" } }) {
            frontmatter {
                title
                intro
            }
        }
        events {
            items {
                summary
                description
                start {
                    dateTime
                }
                end {
                    dateTime
                }
                visibility
            }
        }
    }
`;
