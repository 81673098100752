/** @jsx jsx */
import {jsx, css} from '@emotion/core'
import {useState, useRef, useEffect} from 'react'
import styled from '@emotion/styled'
import vars from '../styles/vars'
import {buttonLink} from '../styles'

const EventContainer = styled('li')`
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    transition: all 0.2s ease-in-out;
    transform: translateY(0);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0);
    ${props =>
        props.withButton
            ? css`
                  cursor: pointer;
              `
            : ''}

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }

    @media screen and (min-width: 950px) {
        flex-direction: row;
    }
`

const detailsWithButtonExpanded = css`
    &::after {
        max-height: 0;
    }
    transition: max-height 0.5s ease-in-out;
    max-height: 100rem;
`

const Details = styled('div')`
    position: relative;
    margin: 4rem 4rem 3rem;
    flex-basis: 70%;

    & > div {
        position: relative;
        ${props => (props.expanded ? detailsWithButtonExpanded : '')}
    }
`

const Date = styled('div')`
    background-color: black;
    color: white;
    padding: 4rem 4rem 3rem;
    flex-basis: 30%;
`

const fireGAEvent = label => {
    // fire click event for Google Analytics
    if (typeof window !== 'undefined' && typeof window.ga !== 'undefined') {
        window.ga('send', 'event', {
            eventCategory: 'UI',
            eventAction: 'click',
            eventLabel: label,
        })
    }
}

const Event = ({
    summary,
    description,
    date: {day, month, date, time, endTime},
}) => {
    const detailsRef = useRef(null)
    const descriptionRef = useRef(null)

    const [expanded, updateExpanded] = useState(false)
    const [withButton, updateWithButton] = useState(false)

    const defaultHeight = 150

    const detailsWithButton = css`
        max-height: ${defaultHeight}px;
        overflow: hidden;
        transition: max-height 0.2s cubic-bezier(0, 1, 0, 1);
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100px;
            max-height: 100px;
            background: linear-gradient(
                0deg,
                #fff 0,
                rgba(255, 255, 255, 0) 100%
            );
            transition: max-height 0.2s linear;
        }
    `

    useEffect(() => {
        if (
            descriptionRef.current.clientHeight +
                detailsRef.current.clientHeight >
            defaultHeight
        ) {
            updateWithButton(true)
        }
    }, [])

    return (
        <EventContainer
            withButton={withButton}
            onClick={() => {
                if (withButton) {
                    fireGAEvent('Expand event (card)')
                    updateExpanded(!expanded)
                }
            }}
        >
            <Date>
                <div
                    css={css`
                        font-family: ${vars.headingsFontFamily};
                        font-size: 2rem;
                        text-transform: uppercase;
                    `}
                >
                    {day}, {month} {date}
                </div>
                <div
                    css={css`
                        text-transform: uppercase;
                    `}
                />
                <div>
                    {time} – {endTime}
                </div>
            </Date>
            <Details expanded={expanded}>
                <div css={withButton && detailsWithButton}>
                    <h5 ref={detailsRef}>{summary}</h5>
                    <p
                        ref={descriptionRef}
                        dangerouslySetInnerHTML={{
                            __html: description,
                        }}
                    />
                </div>
                {withButton && (
                    <button
                        css={css`
                            ${buttonLink}
                        `}
                        onClick={() => {
                            updateExpanded(!expanded)
                            fireGAEvent('Expand event (button)')
                        }}
                    >
                        Read {expanded ? 'less' : 'more'}
                    </button>
                )}
            </Details>
        </EventContainer>
    )
}

export default Event
